import React from 'react';
import { Link } from "gatsby"
import { linkResolver } from '../utils/link_resolver'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Products = ({primary, items}) => {
  return (
    <>
      <div className="w-full pt-12 border-t-2">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-between">
              <h2 className="font-brand leading-none font-black text-blue text-4xl md:text-5xl mb-12">
                {primary.tag}
              </h2>
          </div>
        </div>
      </div>
      <div className="w-full pb-12 border-grey-c border-b-2">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24">
            {items.map((entry,i) => {
              return (
              <div key={i} className="group" data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease">
                <div className="pb-3 bg-blue">
                  <Link to={linkResolver(entry.page_link1)}>
                    <GatsbyImage className="aspect-w-5 aspect-h-3" style={{ width: "100%", height: "100%", display: "block", objectFit: 'cover' }} image={entry.image1.gatsbyImageData} alt={entry.image1.alt ? entry.image1.alt : ''} />
                  </Link>
                </div>
                <div className="mt-6">
                  <Link to={linkResolver(entry.page_link1)} className="font-brand font-bold text-3xl text-blue border-b-4 border-white group-hover:border-blue inline-block">
                    <h3>{entry.heading1}</h3>
                  </Link>
                </div>
                <div className="mt-6">
                  <div className="font-sans prose prose-lg leading-normal mb-3">
                    <PrismicRichText linkResolver={linkResolver} field={entry.text1.richText} />
                  </div>
                </div>
              </div>
            )})}
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
