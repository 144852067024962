import React from 'react';
import { PrismicRichText } from '@prismicio/react'

const Icons = ({primary, items}) => {
  return (
    <>
      <div className="w-full bg-blue relative" data-sal-duration="1000" data-sal="slide-up" data-sal-easing="ease">
        <div className="absolute -inset-0 opacity-10">
          <img className="w-full h-full object-cover" src="/img/topography.svg" alt="" />  
        </div>
        <div className="w-full pt-24 pb-6 relative">
          <div className="w-11/12 mx-auto max-w-screen-2xl">
            <div className="flex justify-between">
              <h2 className="font-brand leading-none font-black text-4xl md:text-5xl text-white mb-6">
                {primary.tag}
              </h2>
            </div>
          </div>
        </div>
        <div className="w-full pb-24 relative">
          <div className="w-11/12 mx-auto max-w-screen-2xl">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-12 md:gap-24">
              {items.map((entry,i) => {
              return (
                <div key={i}>
                  <img src={entry.image1.url} alt={entry.image1.alt ? entry.image1.alt : ''} className="w-20 h-20 inline-block" />
                  <h2 className="font-brand font-bold text-2xl text-white mt-6 mb-3 leading-tight">
                    {entry.heading1}
                  </h2>
                  <div className="font-sans text-white text-lg leading-normal opacity-75">
                    <PrismicRichText field={entry.text1.richText} />
                  </div>
                </div>
              )})}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Icons;
